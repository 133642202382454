import { useState } from 'react';
import {
  Grid,
  Textarea,
  Typography,
  Divider,
  Stack,
  Button,
  Card,
  CardActions,
  CardContent,
  Snackbar
} from '@mui/joy';
import Lottie from 'lottie-react';
import Markdown from 'react-markdown';
import thinking from '../lottie/loading.json';
import { getResponse, getSpeech } from '../modules/firebase';
import SpeechPlayer from './SpeechPlayer';
import {
  Check,
  ContentCopy,
  Favorite,
  RecordVoiceOver
} from '@mui/icons-material';
import { useUser } from '../context/user';
import sound from '../modules/click.json';

const PositiveReframe = () => {
  const [querying, setQuerying] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { user } = useUser();
  const [speech, setSpeech] = useState();
  const [isFetchingSpeech, setIsFetchingSpeech] = useState(false);
  const [originalText, setOriginalText] = useState('');
  const [reframedText, setReframedText] = useState('');

  const reframeText = async () => {
    try {
      setQuerying(true);
      const newText = await getResponse(
        [
          {
            role: 'user',
                content: originalText
          }
        ],
        `You are an expert at concisely transforming messages into their most positive, loving versions. Your task:
  1. Reframe the input text completely, inverting any negative sentiments into positive ones, filled with love and gratitude.
  2. Maintain a very similar message length and sentence structure as the original message. Make sure the reframed message is grammatically correct, sounds natural and flows well.
`

      );
      console.log(newText);
      setReframedText(newText);
      setQuerying(false);
      setShowSnackbar('Text reframed with love!');
    } catch (err) {
      console.error(err.code, err.message, err.details);
      setQuerying(false);
    }
  };

  return (
    <>
      <Grid container m={3} justifyContent="center">
        <Grid xs={12} mt={8}>
          <Typography level="h2" startDecorator={<Favorite />}>
            Loving Reframe
          </Typography>
        </Grid>
        <Grid xs={12} lg={6} sx={{ pt: 3 }}>
          <Stack spacing={3}>
            <Typography level="body-md">
              Enter any text, and it will be transformed into a loving, positive
              perspective.
            </Typography>
            <Textarea
              placeholder="Enter your text..."
              size="lg"
              minRows={11}
              autoFocus
              value={originalText}
              onChange={event => setOriginalText(event.target.value)}
            />
            <Button
              size="lg"
              disabled={originalText === '' || querying}
              onClick={async () => {
                reframeText();
              }}
            >
              Reframe with Love
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Stack
            spacing={3}
            justifyContent="center"
            padding={3}
            alignItems="center"
          >
            {querying && <Lottie animationData={thinking} />}
            {reframedText && !querying && (
              <Card sx={{ width: '100%' }}>
                <Typography level="title-lg">A Loving Reframe</Typography>
                <Typography level="body-sm">
                  Everyone likes you
                </Typography>
                <Divider />
                <CardContent>
                  <Markdown>{reframedText}</Markdown>
                  {speech && (
                    <SpeechPlayer
                      completed={() => {
                        setSpeech(null);
                        console.log('Audio completed.');
                      }}
                      cancelled={() => {
                        setSpeech(null);
                      }}
                      speech={speech}
                    />
                  )}
                </CardContent>
                <CardActions buttonFlex="0 1 120px">
                  <Button
                    variant="outlined"
                    loading={isFetchingSpeech}
                    color="neutral"
                    startDecorator={<RecordVoiceOver />}
                    onClick={async () => {
                      setIsFetchingSpeech(true);
                      let voiceChat = new Audio();
                      voiceChat.autoplay = true;
                      voiceChat.src = `data:audio/wav;base64,${sound.audio}`;

                      const base64Audio = await getSpeech({
                        text: reframedText,
                        voice: user.voice
                      });
                      voiceChat.src = `data:audio/mp3;base64,${base64Audio}`;
                      setSpeech(voiceChat);
                      setIsFetchingSpeech(false);
                    }}
                  >
                    Speak
                  </Button>
                  <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={<ContentCopy />}
                    onClick={() => {
                      navigator.clipboard.writeText(reframedText);
                      setShowSnackbar('Loving reframe copied');
                    }}
                  >
                    Copy
                  </Button>
                </CardActions>
              </Card>
            )}
          </Stack>
        </Grid>
      </Grid>
      {showSnackbar && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          color="success"
          size="sm"
          variant="outlined"
          startDecorator={<Check />}
          open={showSnackbar}
          onClose={() => setShowSnackbar(null)}
        >
          {showSnackbar}
        </Snackbar>
      )}
    </>
  );
};

export default PositiveReframe;
