import { useState } from 'react';
import {
  Grid,
  Textarea,
  Typography,
  Divider,
  Stack,
  Button,
  Card,
  CardActions,
  CardContent,
  Accordion,
  AccordionGroup,
  AccordionSummary,
  AccordionDetails,
  Snackbar
} from '@mui/joy';
import Lottie from 'lottie-react';
import Markdown from 'react-markdown';
import thinking from '../lottie/loading.json';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { getResponse } from '../modules/firebase';
import {
  AssignmentInd,
  Check,
  ContentCopy
  // FileDownload
} from '@mui/icons-material';
import { useUser } from '../context/user';

const Resume = () => {
  const [querying, setQuerying] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { user } = useUser();
  const [workHistory, setWorkHistory] = useState(user?.workHistory);
  const [jobDescription, setJobDescription] = useState('');
  const [customCV, setCustomCV] = useState();

  const generateCustomCV = async () => {
    try {
      setQuerying(true);
      const customCVres = await getResponse([
        {
          role: 'user',
          content: `From this work history: ${workHistory}, generate a new custom CV that is just perfect for this job description: ${jobDescription}. The format of the CV needs to show contact details, a short intro bio, and past work history tweaked to make it a better fit for this role. Take inspiration in the formatting from the work history.`
        }
      ],'You are a genius at creating job applications.');
      console.log(customCVres);
      setCustomCV(customCVres);
      setQuerying(false);
    } catch (err) {
      console.error(err.code, err.message, err.details);
      setQuerying(false);
    }
  };

  return (
    <>
      <Grid container m={3} justifyContent="center">
        <Grid xs={12} mt={8}>
          <Typography level="h2" startDecorator={<AssignmentInd />}>
            Résumé Tailoring
          </Typography>
        </Grid>
        <Grid xs={12} lg={6} sx={{ pt: 3 }}>
          <AccordionGroup size="lg" variant="soft">
            <Accordion>
              <AccordionSummary>Your Work History</AccordionSummary>
              <AccordionDetails sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Typography level="body-md">
                    Enter as much detail about your work history here.
                  </Typography>
                  <Textarea
                    placeholder="Enter your work history..."
                    size="lg"
                    minRows={11}
                    autoFocus
                    value={workHistory}
                    onChange={event => setWorkHistory(event.target.value)}
                  />
                  <Button
                    size="lg"
                    disabled={workHistory === user?.workHistory}
                    onClick={async () => {
                      await updateDoc(doc(getFirestore(), 'users', user.id), {
                        workHistory
                      });
                      setShowSnackbar('Work history updated!');
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>Job Description</AccordionSummary>
              <AccordionDetails sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Typography level="body-md">
                    Paste the job description here.
                  </Typography>
                  <Textarea
                    placeholder="Details of the job you're applying for..."
                    size="lg"
                    minRows={11}
                    autoFocus
                    value={jobDescription}
                    onChange={event => setJobDescription(event.target.value)}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Grid>
        <Grid xs={12} lg={6}>
          <Stack
            spacing={3}
            justifyContent="center"
            padding={3}
            alignItems="center"
          >
            <Typography level="h3">Available actions</Typography>
            <Button
              onClick={() => generateCustomCV()}
              size="lg"
              disabled={querying || jobDescription==='' || user?.workHistory===''}
            >
              Generate Custom CV
            </Button>
            {querying && <Lottie animationData={thinking} />}
            {customCV && (
              <Card>
                <Typography level="title-lg">Your Customised CV 😊</Typography>
                <Divider />
                <CardContent>
                  <Markdown>{customCV}</Markdown>
                </CardContent>
                <CardActions buttonFlex="0 1 120px">
                  <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={<ContentCopy />}
                    onClick={() => {
                      navigator.clipboard.writeText(customCV);
                      setShowSnackbar('CV copied');
                    }}
                  >
                    Copy
                  </Button>
                  {/* <Button
                    variant="solid"
                    color="primary"
                    startDecorator={<FileDownload />}
                  >
                    PDF
                  </Button> */}
                </CardActions>
              </Card>
            )}
          </Stack>
        </Grid>
      </Grid>
      {showSnackbar && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          color="success"
          size="sm"
          variant="outlined"
          startDecorator={<Check />}
          open={showSnackbar}
          onClose={() => setShowSnackbar(null)}
        >
          {showSnackbar}
        </Snackbar>
      )}
    </>
  );
};

export default Resume;
